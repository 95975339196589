import { duration } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import {
  FaArrowRight,
  FaFacebookF,
  FaPhoneAlt,
  FaYoutube,
} from "react-icons/fa";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { IoIosCloseCircle, IoLogoInstagram, IoMdTime } from "react-icons/io";
import { MdOutlineMail } from "react-icons/md";
export default function Address({ card, setCard }) {
  return (
    <motion.div
      animate={{
        opacity: card ? 1 : 0,

        transform: card ? "translateX(0)" : "translateX(-10vh)",
        transition: {
          duration: 0.5,
        },
      }}
      className={`${
        card ? "w-[100vw]" : "hidden"
      } h-[100vh]  duration-500 fixed z-30 right-0 top-0 flex justify-center items-center overflow-y-scroll scroll-m-0 `}
    >
      <div className="w-[100%] max-w-[1400px] top-0">
        <div className="absolute border-l-[2px] px-[2rem] py-[2rem] bg-white border-orange-500 right-0 top-0 w-[30%] flex flex-col gap-5">
          <div className="w-[100%] flex items-center justify-between">
            <img
              src={require("../../Assets/logos.png")}
              className="w-[12rem] h-[3rem] object-cover"
              alt=""
            />
            <IoIosCloseCircle
              onClick={() => setCard(false)}
              className="text-orange-500 text-[50px] "
            />
          </div>
          <p className="mt-10 w-[100%] text-lg leading-[1.7rem]">
            Roogi Group of companies are leading civil government contracting
            firm specializing in providing comprehensive solutions for public
            sector infrastructure projects.
          </p>
          <div className=" flex mt-5 flex-col gap-5">
            <h1 className="text-xl font-bold">Contact Info</h1>
            <div className="w-[100%] flex flex-col gap-5">
              <div className="w-[100%] flex  gap-3">
                <FaLocationDot className="text-2xl text-orange-500" />
                <h1 className="text-lg font-semibold">
                Mudhol District – Bagalkot

                </h1>
              </div>
              <div className="w-[100%] flex items-center gap-3">
                <MdOutlineMail className="text-2xl text-orange-500" />
                <h1 className="text-lg font-semibold">
                  roogiappanna@gmail.com
                </h1>
              </div>

              <div className="w-[100%] flex items-center gap-3">
                <IoMdTime className="text-2xl text-orange-500" />
                <h1 className="text-lg font-semibold">
                  Monday-Saturday, 09am -05pm
                </h1>
              </div>
              <div className="w-[100%] flex items-center gap-3">
                <FaPhoneAlt className="text-2xl text-orange-500" />
                <h1 className="text-lg font-semibold">+91 7411169812</h1>
              </div>
              <div className="header-button w-[100%] flex gap-2">
                <a
                  className="theme-btn bg-[#012F53] w-[100%] justify-center text-white flex gap-2 items-center"
                  href="/contact"
                >
                  GET A QUOTE <FaArrowRight />
                </a>
              </div>
              <div className="w-[100%] flex mt-5 items-center gap-3 ">
                <div className="w-[3rem] h-[3rem] border-[1px] flex items-center justify-center border-gray-300 rounded-full">
                  <FaFacebookF />
                </div>
                <div className="w-[3rem] h-[3rem] border-[1px] flex items-center justify-center border-gray-300 rounded-full">
                  <IoLogoInstagram />
                </div>
                <div className="w-[3rem] h-[3rem] border-[1px] flex items-center justify-center border-gray-300 rounded-full">
                  <FaXTwitter />
                </div>
                <div className="w-[3rem] h-[3rem] border-[1px] flex items-center justify-center border-gray-300 rounded-full">
                  <FaYoutube />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
