import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Team from "../Home/Team";
import Teams from "../UI/Teams";
import Owner from "../UI/TeamOwner";
export default function Container() {
 
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
      <div className="w-[100%] max-w-[1400px] flex-col flex px-[10%] pt-[7rem] justify-center items-center">
        <div className="w-[100%] items-center justify-center flex flex-col gap-5">
          <div className="flex w-[100%] items-center justify-center gap-3 text-[#ff523c]">
            <FaArrowLeft className="text-xl" />{" "}
            <h1 className="text-md font-semibold uppercase tracking-wider">
            our team members
            </h1>
            <FaArrowRight className="text-xl" />
          </div>
          <h1 className="text-5xl capitalize  font-bold text-center">
          our technical team
          </h1>
        </div>
        <div className="mt-10 w-[100%] flex items-center justify-center ">
            <div className="w-[100%] grid grid-cols-4 gap-5">
             <Teams/>
             <Owner/>
             <Teams/>
             <Teams/>
             <Teams/>
             <Teams/>
             <Teams/>
             <Teams/>

            </div>

        </div>
      </div>
    </section>
  );
}
