import React, { useState } from 'react'
import Address from '../Menu/Address'
import Banner from '../About/Banner'
import Client from '../Home/Client'
import Footer from '../Home/Footer'
import Header from '../Home/Header'
import Me from './Me'

export default function DetailCOver() {
    const [card,setCard]= useState(false)
    return (
      <>
        <Header setCard={setCard}/>
  
        <Address setCard={setCard} card={card} />
        <div className="relative w-[100%] h-[100%]">
        <div className={` ${ card&&'w-[100%] absolute left-0  top-0  h-[100%] z-10 bg-[#080808bd]'}`} />
        <Banner name={'Team Details'} title={'Team Details'}/>
        <Me/>
        <Client/>
        <Footer/>
      </div>
      
    </>
  )
}
