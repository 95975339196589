import React  from 'react'
import Blogss from '../UI/Blogss'
import img1 from '../../Assets/1.jpeg'
import img2 from '../../Assets/2.jpeg'
import img3 from '../../New/1.jpeg'
import img4 from '../../New/22.jpeg'
import img5 from '../../New/23.jpeg'
import img6 from '../../New/2.jpeg'
export default function Detail() {
  
  return (
    <section className='w-[100vw] h-[100%] flex items-center justify-center overflow-hidden'>
      <div className="w-[100%] flex items-center overflow-hidden px-[8%] py-[5rem]">
        <div className="w-[100%] grid grid-cols-3 gap-5">
           <Blogss img={img1}/>
           <Blogss img={img2}/>
           <Blogss img={img3}/>
           <Blogss img={img4}/>
           <Blogss img={img5}/>
           <Blogss img={img6}/>

        </div>
      </div>
    </section>
  )
}
