
import React from 'react'
import { FaArrowLeft, FaArrowRight} from 'react-icons/fa'
import Member from '../UI/Member'
import img2 from  '../AR/stone.jpeg'
import img1 from  '../AR/s11.png'
import img3 from '../AR/s2.72fff376e4ddbbff255b.jpeg'
export default function Team() {
  
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
    <div className="w-[100%] max-w-[1400px] bg3 flex-col flex max-md:px-[3%] md:px-[7%] pb-[3rem] max-md:pt-[3rem] md:pt-[3rem] justify-center items-center">
      <div className="w-[100%] items-center justify-center flex flex-col gap-5">
        {/* <div className="flex w-[100%] items-center justify-center gap-3 text-[#ff523c]">
          <FaArrowLeft className="text-xl" />{" "}
          <h1 className="text-md font-semibold uppercase tracking-wider">
          Our expert team
          </h1>
          <FaArrowRight className="text-xl" />
        </div> */}
        <h1 className="md:text-5xl max-md:text-3xl text-orange-500 font-bold text-center">OUR OTHER VENTURES</h1>
      </div>
      <div className='w-[100%] mt-10 grid max-lg:grid-cols-1 lg:grid-cols-3 max-lg:gap-4 lg:gap-10'>
    
       <Member name='Appanna Rangappa Roogi'position='Real Estate- Commercials'    img={img1} link={'service/1'} />
       <Member name='Shrikant Appanna Roogi' position='Stone Crushers'   img={img2}  link={'service/2'} />
       <Member name=' Tara Appanna Roog'  position='Fuel Point- Nayara Energy' img={img3} link={'service/3'} />
     
       
      </div>
    </div>
    </section>
  )
}
