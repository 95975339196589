import { motion } from 'framer-motion'
import React from 'react'
export default function Banner({title,name}) {
  return (
    <section className='w-[100vw] bg7 flex items-center justify-center overflow-hidden'>
      <div className="w-[100%] max-w-[1400px] bg-[#020202c7] relative flex px-[7%] py-[12rem] items-center gap-5">
        
      <motion.div
     animate={{
        y:[0,30,0],
        transition:{
            duration:4,
            repeat:Infinity,
            repeatType:'loop'
        }
    }}
        className='absolute bottom-[20%] z-20 right-0'>
            <img src={require('../../Assets/vector.webp')} className='' alt="banner" />
        </motion.div><motion.div
     animate={{
        x:[0,30,0],
        transition:{
            duration:4,
            repeat:Infinity,
            repeatType:'loop'
        }
    }}
        className='absolute bottom-0 z-20 right-[5%]'>
            <img src={require('../../Assets/breadcrumb-image.webp')} className='w-[90%]' alt="" />
        </motion.div>
       <div className='absolute bottom-0 z-10 h-[100%] right-[10%]'>
            <img src={require('../../Assets/breadcrumb-bar (1).webp')} className='h-[100%]' alt="" />
        </div>
        <div className='z-30'>
            <h1 className="text-[#fff] max-md:text-[2.7rem] md:text-[4.5rem] max-md:leading-[3.3rem] md:leading-[5rem] uppercase font-bold">{title}</h1>
            <span className='text-xl text-white font-semibold'>
                Home / <span className='text-[#fc9d22]'>{name}</span>
            </span>
        </div>
      </div>
    </section>
  )
}
