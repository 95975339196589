import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
export default function Blogss({img}) {
    const [card,setCard]= useState(false)
  return (
    <div onMouseEnter={()=>setCard(true)} onMouseLeave={()=>setCard(false)} className='w-[100%] flex flex-col '>
              <div className='h-[12rem] relative w-[100%] overflow-hidden'>

                <motion.div
                animate={{
                    opacity:card?0:1,
                    width:!card?'100%':'50%',
                    transform:{
                        translateX:'50%',
                        scaleX:'50%'
                    },
                    transition:{duration:0.5}
                }}
                className="h-[100%] absolute top-0 left-0 overflow-hidden">
                    <img src={img} className='h-[100%] w-[100%] object-cover' alt="" />
                </motion.div>
                <motion.div
                animate={{
                    opacity:!card?0:1,
                    width:'100%',
                   transition:{
                    duration:0.5
                   }
                }}
                className="h-[100%] absolute top-0 right-0 overflow-hidden">
                    <img src={img} className='h-[100%] w-[100%] object-cover' alt="" />
                </motion.div>
              </div>
              <div className={`${card?'bg-black text-white': 'bg-blue-100 text-black' }
              flex flex-col gap-5
              p-[1.5rem] duration-500`}>
                    <div className='flex items-center justify-between'>
                    <h1 className={`${!card && 'text-gray-600 '} text-lg font-semibold`}>Construction</h1>
                    <h1 className={`${!card && 'text-gray-600 '} text-lg font-semibold`}>07 July 2024</h1>
                    </div>
                    <p className='text-xl tracking-wide font-bold'>
                    Latest Work Was Disapproved by the Supervisor.
                    </p>
                    <div  className={`w-[100%] border-b-[2px] ${card?'border-white':'border-gray-300'} my-3`}/>
                    <div className='flex w-[100%] items-center justify-between'>
                        <div className='flex gap-2 items-center'>
                            <div className='w-[3rem] h-[3rem] flex items-center justify-center overflow-hidden rounded-full'>
                                <img src={require('../../Assets/01 (1).webp')}  alt="" />

                            </div>
                            
                            <div className="flex flex-col gap-0">
                                <h1 className='font-bold text-md'>Admin</h1>
                                <span className={`${!card&&'text-gray-500'} text-sm`}>Guy Hawkins</span>
                            </div>
                        </div>
                        <a href="/blog/1">
                        <div className='flex gap-3 text-lg font-bold cursor-pointer items-center text-orange-500'>
                            <span>Read More</span><FaArrowRight />
                        </div>
                        </a>
                    </div>
              </div>

            </div>
  )
}
