import React from 'react'
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
export default function Client() {
  return (
    <section className="w-[100vw] flex items-center justify-center bg-white overflow-hidden">
      <div className="w-[100%] max-w-[1400px] flex px-[10%] pt-[3rem] pb-[5rem] flex-col gap-14 justify-center items-center">
      
        <h1 className="lg:text-5xl max-lg:text-3xl text-orange-500 font-bold text-center">OUR HAPPY CLIENTS </h1>
     
        <div className='w-[100%] flex items-center justify-center'>
        <Swiper
              navigation={{
                prevEl: ".custom-prev", // Target custom classes for navigation
                nextEl: ".custom-next",
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
                spaceBetween={30}// Show one slide at a time
                loop={true} // Enable looping
                speed={2000} // Slow transition speed in ms
                autoplay={{
                  delay: 1000, // Delay between transitions in ms
                  disableOnInteraction: false, // Continue autoplay even after interaction
                }}
                pagination={{ clickable: true }} // Show pagination dots
              
                modules={[Autoplay, Navigation, Pagination]}
                className="w-[100%] h-[100%] flex "
              >
                <SwiperSlide>
                    <div className='flex items-center w-[100%] justify-center '>
                        <img src={require('../AR/CLIENTS/BTDA_enhanced-removebg-preview.png')}  className='w-[13rem] ' alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-[100%] flex items-center justify-center '>
                        <img src={require('../AR/CLIENTS/KBJNL.png')} className='w-[15rem]' alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-[100%] flex items-center justify-center '>
                        <img src={require('../AR/CLIENTS/n1.jpeg')} className='w-[15rem]'  alt="" />
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <div className='w-[100%] flex items-center justify-center '>
                        <img src={require('../AR/CLIENTS/KRIDCL-removebg-preview.png')} className='w-[15rem]' alt="" />
                    </div>
                </SwiperSlide> */}
                <SwiperSlide>
                    <div className='w-[100%] flex items-center justify-center '>
                        <img src={require('../AR/CLIENTS/SHDP-removebg-preview.png')} className='w-[15rem]' alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-[100%] h-[100%] object-cover flex items-center justify-center '>
                        <img src={require('../AR/CLIENTS/PMGSY.png')} alt="" className='w-[15rem] h-[100%] object-cover' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-[100%] h-[100%] object-cover flex items-center justify-center '>
                        <img src={require('../AR/CLIENTS/n2.jpeg')} alt="" className='w-[15rem] h-[100%] object-cover' />
                    </div>
                </SwiperSlide>
                
            </Swiper>

        </div>
    </div>
    </section>
  )
}
