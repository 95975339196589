import { motion } from "framer-motion";
import React, { useState } from "react";
import {  FaFacebookF, FaInstagram, FaLink, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
export default function Owner() {
    const [card,setCard]=  useState(true)
  return (
    <div  className="w-[100%] flex flex-col overflow-hidden">
    <div className="relative w-[100%] flex items-center justify-center h-[100%]">
        <img src={require('../../Assets/05.webp')} alt="team" className="w-[100%]"/>
        <motion.div 
        animate={{
            opacity:card?1:0,
            x:!card?20:0,
            transition: { duration: 0.5 },
        }}
        style={{zIndex:2}} className="flex flex-col gap-5 text-xl absolute bg-black text-white right-0  py-[2rem] px-3">
        <FaFacebookF />     
        <FaInstagram />      
        <FaXTwitter />    
        <FaYoutube />
        </motion.div>
        <div className={`absolute top-0 left-0 w-[100%] h-[100%] ${card&&'bg-[#02020280]'} duration-300`}/>
    </div>
    <div className={`${!card ?'bg-black text-white ': 'bg-orange-500 text-white'} py-3 transition-all duration-300 flex w-[100%]  justify-between items-center`}>
            <div className="border-l-[4px] flex flex-col border-orange-500  pl-[2rem]">
                <h1 className="text-lg font-bold">Girish R J</h1>
                <span className="text-sm capitalize">Sr. engineer</span>
            </div>
            <a href="/team/1">
            <FaLink className="text-xl mr-[1rem]" />
            </a>
    </div>
</div>
  )
}
