import React from 'react'
import { FaArrowRight, FaCalendarAlt, FaFacebookF, FaInstagram, FaTag, FaUser, FaYoutube } from 'react-icons/fa'
import { CiCalendarDate } from "react-icons/ci";
import { requirePropFactory } from '@mui/material';
import { FaXTwitter } from 'react-icons/fa6';
export default function DetailsBlog() {
  return (
    <section className='w-[100vw] flex items-center justify-center overflow-hidden '>
      <div className="max-w-[1400px] w-[100%] px-[8%] pt-[6rem] pb-[3rem]">
        <div className='w-[100%]  flex gap-10'>
        <div className="w-[65%] flex flex-col gap-5">
            <img src={require('../../Assets/post-4.jpg')} className='w-[100%]' alt="blog1" />
        <div className='w-[100%] text-gray-500 flex gap-10 items-center '>
            <div className='flex gap-2 items-center text-sm font-bold '>
            <FaUser className=' text-orange-500' /> <h1> By Admin</h1>
            </div>
            <div className='flex gap-2 items-center text-sm font-bold '>
            <CiCalendarDate className=' text-orange-500 text-xl' /> <h1>18 Dec, 2024</h1>
            </div>
            <div className='flex gap-2 items-center text-sm font-bold '>
            <FaTag  className=' text-orange-500' /> <h1>Technology</h1>
            </div>
        </div>
        <div className="border-b-[1px] my-1 border-gray-200 w-[100%]" />
        <h1 className='text-4xl font-bold'>
        Supervisor Disapproved of Latest Work.
        </h1>
        <p className='w-[100%] text-gray-600 leading-[1.8rem] tracking-wide'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. A incidunt obcaecati dolores magni in unde necessitatibus labore aliquid, tenetur quaerat nobis, laudantium error doloribus delectus provident esse veniam tempora minima.
        </p>
        <p className='w-[100%] text-gray-600 leading-[1.8rem] tracking-wide'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. A incidunt obcaecati dolores magni in unde necessitatibus labore aliquid, tenetur quaerat nobis, laudantium error doloribus delectus provident esse veniam tempora minima.
        </p>
        <p className='w-[100%] text-gray-600 leading-[1.8rem] tracking-wide'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. A incidunt obcaecati dolores magni in unde necessitatibus labore aliquid, tenetur quaerat nobis, laudantium error doloribus delectus provident esse veniam tempora minima.
        </p>
        <div className='bg-slate-100 py-5 border-l-[3px] border-orange-500 px-[3rem]'>
            <p className='text-lg font-semibold  italic tracking-wide '>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt cupiditate aliquid nobis deserunt dolorum eligendi, veritatis quod, facilis non eum voluptatum velit illo voluptatem molestias, possimus magnam distinctio dicta magni!
            </p>

        </div>
        <p className='w-[100%] text-gray-600 leading-[1.8rem] tracking-wide'>
        Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat. In iaculis arcu eros.


        </p>
        <div className='w-[1005] grid grid-cols-2 gap-5'>
            <img src={require('../../Assets/01 (2).webp')} className='w-[100%]' alt="gallary" />
            <img src={require('../../Assets/01 (2).webp')} className='w-[100%]' alt="gallary" />

        </div>
        <p className='w-[100%] text-gray-600 leading-[1.8rem] tracking-wide'>
        Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat. In iaculis arcu eros.


        </p>
        <div className="w-[100%] border-b-[1px] border-gray-300 my-2" />
        <div className='flex justify-between items-center w-[100%]'>
            <div className='flex text-lg items-center gap-5'>
                <h1 className="font-semibold">
                    Tags
                </h1>
                <div className='px-5 w-fit py-2 text-sm border-[1px] border-gray-200 bg-white duration-300 hover:bg-orange-500 hover:text-white font-semibold'>
                       Design
                    </div>
                    <div className='px-5 w-fit py-2 text-sm border-[1px] border-gray-200 bg-white duration-300 hover:bg-orange-500 hover:text-white font-semibold'>
                       Travel
                    </div>
                    <div className='px-5 w-fit py-2 text-sm border-[1px] border-gray-200 bg-white duration-300  hover:bg-orange-500 hover:text-white font-semibold'>
                       Marketing
                    </div>
            </div>
            <div className='flex gap-4 items-center'>
                <h1 className='font-bold'>Share :</h1>
                    <div className="flex items-center gap-1">
                    <FaFacebookF /><FaInstagram /><FaXTwitter /><FaYoutube />
                    </div>
            </div>

        </div>
        </div>
        <div className="w-[30%] flex flex-col gap-5">
            <div className="px-[1.5rem] flex flex-col bg-slate-100 py-[2.5rem]">
                <div className="relative w-fit text-xl font-semibold tracking-wide">
                    <h1>Categories</h1>
                    <div className="absolute border-b-[3px] bottom-[-1rem] left-0 border-black w-[30%]"/>
                    <div className="absolute border-b-[3px] bottom-[-1rem] border-orange-500 left-[35%] w-[60%]"/>
                </div>
                <div className="w-[100%] font-semibold mt-10 flex flex-col gap-4">
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">Construction</h1>6
                    </div>
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">House Renovation</h1>4
                    </div>
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">Material Supply</h1>6
                    </div>
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">Project Management</h1>8
                    </div>
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">Heating and Water</h1>11
                    </div>

                </div>

            </div>
            <div className="px-[1.5rem] flex flex-col bg-slate-100 py-[2.5rem]">
                <div className="relative w-fit text-xl font-semibold tracking-wide">
                    <h1>Recent Post</h1>
                    <div className="absolute border-b-[3px] bottom-[-1rem] left-0 border-black w-[30%]"/>
                    <div className="absolute border-b-[3px] bottom-[-1rem] border-orange-500 left-[35%] w-[60%]"/>
                </div>
                <div className='w-[100%] mt-10 flex flex-col gap-5'>
                    <div className='flex w-[100%] border-b-[1px] border-gray-300 pb-[1.5rem] gap-3 items-center'>
                        <img src={require('../../Assets/01 (1).webp')} className='w-[5rem] h-[5rem]' alt="" />
                        <div className="flex flex-col gap-2">
                            <div className="flex text-lg font-semibold  text-gray-600 gap-2 items-center">
                            <FaCalendarAlt  className='text-orange-500'/> <h1>09 July 2024</h1>
                            </div>
                            <span className='text-xl leading-[1.5rem] font-semibold tracking-wide'>
                            The Beast Group
                            Present And
                            </span>
                        </div>
                    </div>
                    <div className='flex w-[100%] border-b-[1px] border-gray-300 pb-[1.5rem] gap-3 items-center'>
                        <img src={require('../../Assets/01 (1).webp')} className='w-[5rem] h-[5rem]' alt="" />
                        <div className="flex flex-col gap-2">
                            <div className="flex text-lg font-semibold  text-gray-600 gap-2 items-center">
                            <FaCalendarAlt  className='text-orange-500'/> <h1>09 July 2024</h1>
                            </div>
                            <span className='text-xl leading-[1.5rem] font-semibold tracking-wide'>
                            The Beast Group
                            Present And
                            </span>
                        </div>
                    </div>
                    <div className='flex w-[100%] border-b-[1px] border-gray-300 pb-[1.5rem] gap-3 items-center'>
                        <img src={require('../../Assets/01 (1).webp')} className='w-[5rem] h-[5rem]' alt="" />
                        <div className="flex flex-col gap-2">
                            <div className="flex text-lg font-semibold  text-gray-600 gap-2 items-center">
                            <FaCalendarAlt  className='text-orange-500'/> <h1>09 July 2024</h1>
                            </div>
                            <span className='text-xl leading-[1.5rem] font-semibold tracking-wide'>
                            The Beast Group
                            Present And
                            </span>
                        </div>
                    </div>
                    <div className='flex w-[100%]   gap-3 items-center'>
                        <img src={require('../../Assets/01 (1).webp')} className='w-[5rem] h-[5rem]' alt="" />
                        <div className="flex flex-col gap-2">
                            <div className="flex text-lg font-semibold  text-gray-600 gap-2 items-center">
                            <FaCalendarAlt  className='text-orange-500'/> <h1>09 July 2024</h1>
                            </div>
                            <span className='text-xl leading-[1.5rem] font-semibold tracking-wide'>
                            The Beast Group
                            Present And
                            </span>
                        </div>
                    </div>
                </div>
             </div>
             <div className="px-[1.5rem] flex flex-col bg-slate-100 py-[2.5rem]">
                <div className="relative w-fit text-xl font-semibold tracking-wide">
                    <h1>Popular Tag</h1>
                    <div className="absolute border-b-[3px] bottom-[-1rem] left-0 border-black w-[30%]"/>
                    <div className="absolute border-b-[3px] bottom-[-1rem] border-orange-500 left-[35%] w-[60%]"/>
                </div>
                <div className='w-[100%] mt-10 flex flex-wrap gap-3'>
                    <div className='px-5 w-fit py-2 text-md bg-white duration-300 text-gray-500 hover:bg-orange-500 hover:text-white font-semibold'>
                        Building

                    </div>
                    <div className='px-5 w-fit py-2 text-md bg-white duration-300 text-gray-500 hover:bg-orange-500 hover:text-white font-semibold'>
                     Construction
                    </div>
                    <div className='px-5 w-fit py-2 text-md bg-white duration-300 text-gray-500 hover:bg-orange-500 hover:text-white font-semibold'>
                       Marketing
                    </div>
                    <div className='px-5 w-fit py-2 text-md bg-white duration-300 text-gray-500 hover:bg-orange-500 hover:text-white font-semibold'>
                       Design
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </section>
  )
}
