import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route,  Routes } from 'react-router-dom'
// import HomeCOntainer from './Components/Home/HomeCOntainer.jsx'
import AnimatedCursor from 'react-animated-cursor'
// import AboutCover from './Components/About/AboutCover.jsx'
// import ServiceCover from './Components/Service/ServiceCover.jsx'
// import ProjectContainer from './Components/Projects/ProjectContainer.jsx'
import BlogCover from './Components/Blogs/BlogCover.jsx'
// import ContactCover from './Components/Contact/ContactCover.jsx'
import TeamCOnatin from './Components/Team/TeamCOnatin.jsx'
import DetailCOver from './Components/TeamDetails.jsx/DetailCOver.jsx'
// import DetailsCover from './Components/ServiceDetails/DetailsCover.jsx'
import BlogsContains from './Components/BlogDetails/BlogsContains.jsx'
// import ProjectsContains from './Components/ProjectDetails/ProjectsContains.jsx'
import { FiLoader } from "react-icons/fi";

const HomeCOntainer=lazy(()=>import('./Components/Home/HomeCOntainer.jsx'))
const AboutCover=lazy(()=>import('./Components/About/AboutCover.jsx'))
const ServiceCover=lazy(()=>import('./Components/Service/ServiceCover.jsx'))
const ProjectContainer=lazy(()=>import( './Components/Projects/ProjectContainer.jsx'))
const ContactCover=lazy(()=>import('./Components/Contact/ContactCover.jsx'))
const ProjectsContains=lazy(()=>import('./Components/ProjectDetails/ProjectsContains.jsx'))
const DetailsCover=lazy(()=>import('./Components/ServiceDetails/DetailsCover.jsx'))

export default function App() {
  return (
    <>
    <BrowserRouter>
    
        <Suspense fallback={<div className='w-[100vw] h-[100vh] flex items-center justify-center '>
          <FiLoader  className='text-3xl animate-spin duration-300'/>
        </div>}>
      <Routes>
        <Route path='/' element={<HomeCOntainer/>} />
        <Route path='/about' element={<AboutCover/>} />
        <Route path='/service' element={<ServiceCover/>} />
        <Route path='/projects' element={<ProjectContainer/>} />
        <Route path='/blog' element={<BlogCover/>} />
        <Route path='/contact' element={<ContactCover/>} />
        <Route path='/team' element={<TeamCOnatin/>} />
        <Route path='/team/:id' element={<DetailCOver/>} />
        <Route path='/service/:id' element={<DetailsCover/>} />
        <Route path='/blog/:id' element={<BlogsContains/>} />
        <Route path='/project/:id' element={<ProjectsContains/>} />

      </Routes>
        </Suspense>
      
      <AnimatedCursor
      innerSize={10}
      outerSize={10}
      color='123, 11, 141'
      borderColor='blue '
      outerAlpha={0.4}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link',
        {
          target: '.custom',
          options: {
            innerSize: 12,
            outerSize: 12,
            color: '37, 37, 37',
            outerAlpha: 0.3,
            innerScale: 0.7,
            outerScale: 5
          }
        }
      ]}
    />
    </BrowserRouter>
    </>
    
  )
}
