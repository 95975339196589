import React from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Me() {
  return (
    <section className="w-[100vw] h-[100%] flex items-start justify-center overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[7%] pt-[6rem] ">
        <div className="w-[100%] border-[1px] border-gray-3000 p-[2rem] flex gap-14">
          <div className="w-[35%] ">
            <img
              src={require("../../Assets/05.webp")}
              className="w-[100%]"
              alt="team1"
            />
          </div>
          <div className="w-[60%] flex flex-col gap-5">
            <div className="flex flex-col gap-0">
              <h1 className="text-[2.5rem] leading-[2.7rem] font-bold tracking-wide">
                Shikhon Islam
              </h1>
              <span className="text-lg text-semibold text-gray-500 capitalize">
                Sr. engineer
              </span>
            </div>
            <p className="text-gray-600 leading-[1.8rem] tracking-wide">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nostrum
              accusantium natus nulla beatae cupiditate consequuntur maxime
              quasi reprehenderit? Illo quasi cupiditate enim porro? Nulla modi
              minus blanditiis at repellat molestias?
            </p>
            <div className="w-[100%] flex flex-col gap-4">
              <div className="flex text-xl w-[100%]">
                <h1 className="font-bold w-[35%]">Phone Number:</h1>
                <span className="text-gray-500 text-lg">+91 1234567890</span>
              </div>
              <div className="flex text-xl w-[100%]">
                <h1 className="font-bold w-[35%]">Email:</h1>
                <span className="text-gray-500 text-lg">example@gmail.com</span>
              </div>
              <div className="flex text-xl w-[100%]">
                <h1 className="font-bold w-[35%]">Website:</h1>
                <span className="text-gray-500 text-lg">
                  http://example.com/example
                </span>
              </div>
              <div className="flex text-xl w-[100%]">
                <h1 className="font-bold w-[35%]">Experience:</h1>
                <span className="text-gray-500 text-lg">15 Years</span>
              </div>
              <div className="flex text-xl w-[100%]">
                <h1 className="font-bold w-[35%]">Availability:</h1>
                <span className="text-gray-500 text-lg">
                  Full Time Employee
                </span>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="w-[3rem] h-[3rem] flex items-center justify-center bg-slate-100 rounded-full text-orange-500">
                <FaYoutube />
              </div>
              <div className="w-[3rem] h-[3rem] flex items-center justify-center bg-slate-100 rounded-full text-orange-500">
                <FaFacebookF />
              </div>
              <div className="w-[3rem] h-[3rem] flex items-center justify-center bg-slate-100 rounded-full text-orange-500">
                <FaInstagram />
              </div>
              <div className="w-[3rem] h-[3rem] flex items-center justify-center bg-slate-100 rounded-full text-orange-500">
                <FaXTwitter />
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] mb-[2rem] mt-5 flex gap-10 ">
          <div className="w-[35%] flex flex-col gap-5">
            <h1 className="text-[2.5rem] font-bold">About Me</h1>
            <p className="text-gray-600 leading-[1.7rem]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
              corrupti fuga quasi totam nobis nihil perspiciatis molestias, aut
              provident, tenetur corporis, alias id. Eligendi quibusdam cum,
              quas eveniet quod cumque! There are many variations of passages of
              Lorem Ipsum available.
            </p>
            <p className="text-gray-600 leading-[1.7rem]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
              corrupti fuga quasi totam nobis nihil perspiciatis molestias, aut
              provident, tenetur corporis, alias id. Eligendi quibusdam cum,
              quas eveniet quod cumque!
            </p>
          </div>
          <div className="w-[60%] flex flex-col gap-5">
            <h1 className="text-[2.5rem] font-bold">Personal skills</h1>
            <p className="text-gray-600 leading-[1.7rem]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
              corrupti fuga quasi totam nobis nihil perspiciatis molestias, aut
              provident, tenetur corporis, alias id. Eligendi quibusdam cum,
              quas eveniet quod cumque!
            </p>
            <div className="flex w-[100%] flex-col gap-3">
              <div className="flex flex-col gap-2 ">
                <div className="flex items-center relative text-[#03355A] font-bold text-lg">
                  <h1 className="text-black text-left">General Consulting</h1>
                  <span className=" ml-[50%]">80%</span>
                </div>
                <div className="w-[100%] py-2 bg-black overflow-hidden relative rounded-full">
                  <div className="absolute w-[80%] h-[100%] z-9 bg-[#ff4f38] top-0 left-0"></div>
                </div>
              </div>
              <div className="flex flex-col gap-2 ">
                <div className="flex items-center relative text-[#03355A] font-bold text-lg">
                  <h1 className="text-black text-left">Design & Build</h1>
                  <span className=" ml-[60%]">90%</span>
                </div>
                <div className="w-[100%] py-2 bg-black overflow-hidden relative rounded-full">
                  <div className="absolute w-[90%] h-[100%] z-9 bg-[#ff4f38] top-0 left-0"></div>
                </div>
              </div>
              <div className="flex flex-col gap-2 ">
                <div className="flex items-center relative text-[#03355A] font-bold text-lg">
                  <h1 className="text-black text-left">
                    Construction Management
                  </h1>
                  <span className=" ml-[30%]">75%</span>
                </div>
                <div className="w-[100%] py-2 bg-black overflow-hidden relative rounded-full">
                  <div className="absolute w-[75%] h-[100%] z-9 bg-[#ff4f38] top-0 left-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
