import React, { useEffect, useState } from "react";
import { FaArrowRight, FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdArrowDropdown, IoMdMenu } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineMailOutline, MdOutlineMenu } from "react-icons/md";

export default function Header({ setCard }) {
  const [isFixed, setIsFixed] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // Check if the scroll position is greater than the navbar's original position
      if (window.scrollY > 160) {
        // Adjust the threshold as needed
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      id="header"
      className={`${
        isFixed ? "fixed  duration-500 " : "relative"
      } w-[100vw] duration-500 transition-all relative bg-white flex max-md:z-40 md:z-30 text-[#252525] items-center justify-center `}
    >
      {menu && (
        <div className="w-[100%] border-l-[3px] border-orange-500 h-[100vh] flex flex-col gap-10 text-xl items-center justify-center top-0 bg-white fixed z-40">
          <div>
            <IoCloseSharp
              onClick={() => setMenu(false)}
              className="text-3xl absolute top-[2rem] right-[2rem]"
            />
          </div>
          <a
            href="/"
            className="hover:text-orange-500 uppercase font-bold duration-500 cursor-pointer"
          >
            Home
          </a>
          <a
            href="/about"
            className="hover:text-orange-500 uppercase font-bold duration-500 cursor-pointer"
          >
            About Us
          </a>
          {/* <a
                  href="/team"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  Our Team
                </a> */}
          <div className="hover:text-orange-500 z-50 duration-500 cursor-pointer">
            <div className="relative   text-black  uppercase font-bold">
              <div
                onClick={() => setToggle(() => !toggle)}
                className="flex items-center gap-3"
              >
                <h1 className="hover:text-orange-500">OUR VENTURES</h1>
                <IoMdArrowDropdown />
              </div>
              {toggle && (
                 <div className="absolute rounded-b-xl shadow-lg capitalize bottom-[-9.4rem] left-[-2rem] p-[1rem] text-sm w-[13rem] h-fit bg-white flex flex-col gap-2 font-bold ">
                 <a href="/service/1" className="hover:text-orange-500">
                 Real Estate- Commercials
                 </a>
                 <a href="/service/2" className="hover:text-orange-500">
                 Stone Crushers
                 </a>
                 <a href="/#banner" className="hover:text-orange-500">
                 Real Estate Layouts
                 </a>
                 <a href="/service/3" className="hover:text-orange-500">
                 Fuel Point- Nayara Energy
                 </a>
               </div>
              )}
            </div>
            {/* <select  className=" w-[13rem] text-black hover:text-orange-500 uupercase font-bold relative outline-none px-3 py-3">
                  <div className="absolute  ">OUR ExXPERTISE</div>
                  <option defaultValue="OUR EXPERTISE"></option>
                  <option value="Roogi stone crushers">Roogi stone crushers</option>
                  <option value="Roogi Real Estate ">Roogi Real Estate </option>
                  <option value="Roogi Fuel Point constructs">Roogi Fuel Point constructs</option>
                  </select> */}
          </div>
          <a
            href="/projects"
            className="hover:text-orange-500 uppercase font-bold duration-500 cursor-pointer"
          >
            Our Projects
          </a>
          {/* <a
                  href="/blog"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  Blogs
                </a> */}
          <a
            href="/contact"
            className="hover:text-orange-500 uppercase font-bold duration-500 cursor-pointer"
          >
            Contact Us
          </a>
        </div>
      )}
      <div className="max-w-[1400px] max-md:py-3 relative flex items-center justify-center w-[100%] max-md:px-[5%] md:px-[3rem] pb-3">
        <div className="w-[100%] flex items-center gap-0 justify-between">
          <img
            src={require("../AR/logo.png")}
            className="max-md:w-[12rem] md:w-[20rem]"
            alt=" logo"
          />
          <div className="flex items-center flex-col gap-4">
            <MdOutlineMenu
              onClick={() => setMenu(true)}
              className="text-2xl lg:hidden"
            />
            <div
              className={`${
                isFixed && "hidden"
              } flex  max-lg:hidden w-fit ml-[5%] p-[1rem] gap-5 items-center`}
            >
              <div className="w-fit items-center  flex gap-2">
                <div className="w-[3rem] bg-[#04355A] h-[3rem] flex items-center justify-center border-[1px] border-gray-400 rounded-full">
                  <FaLocationDot className="text-xl text-[#ff793a]" />
                </div>
                <div className="flex w-[80%] flex-col gap-1">
                  <h1 className="text-sm text-gray-500 font-[600]">
                    Office location
                  </h1>
                  <a
                    href="https://maps.app.goo.gl/6RW1EKnLqeAYvuqN6"
                    className="text-[14px] font-bold "
                  >
                   Mudhol District – Bagalkot
                  </a>
                </div>
              </div>
              <div className="w-fit items-center flex gap-2">
                <div className="w-[3rem] h-[3rem] bg-[#04355A]  flex items-center justify-center border-[1px] border-gray-400 rounded-full">
                  <MdOutlineMailOutline className="text-xl text-[#ff793a]" />
                </div>
                <div className="flex flex-col gap-1">
                  <a href="#" className="text-sm text-gray-500 font-[600]">
                    send email
                  </a>
                  <a href="mailto:roogiappanna@gmail.com">
                    <p className="text-[14px] font-bold ">
                      roogiappanna@gmail.com
                    </p>
                  </a>
                </div>
              </div>
              <div className="w-fit items-center flex gap-2">
                <div className="w-[3rem] bg-[#04355A] h-[3rem] flex items-center justify-center border-[1px] border-gray-400 rounded-full">
                  <FaPhoneAlt className="text-xl text-[#ff793a]" />
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="text-sm text-gray-500 font-[600]">
                    contact now
                  </h1>
                  
                    <p className="text-[14px] font-bold ">7411169812</p>
                 
                </div>
              </div>
              {/* <div className="header-button flex gap-2">
                <a
                  className="theme-btn bg-[#ff793a] text-white flex gap-2 items-center"
                  href="https://adorable-genie-784ab0.netlify.app/"
                >
                  Real Estate <FaArrowRight />
                </a>
              </div> */}
            </div>
            <div className="flex items-center py-3 max-lg:hidden justify-between gap-20">
              <div className="flex items-center xl:text-[16px] lg:text-sm tracking-wider font-[600] gap-10 lg:ml-[0%] xl:ml-[10rem]">
                <a
                  href="/"
                  className="hover:text-orange-500 uppercase font-bold duration-500 cursor-pointer"
                >
                  Home
                </a>
                <a
                  href="/about"
                  className="hover:text-orange-500 uppercase font-bold duration-500 cursor-pointer"
                >
                  About Us
                </a>
                {/* <a
                  href="/team"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  Our Team
                </a> */}
                <div className="hover:text-orange-500 z-50 duration-500 cursor-pointer">
                  <div className="relative   text-black  uppercase font-bold">
                    <div
                      onClick={() => setToggle(() => !toggle)}
                      className="flex items-center gap-3"
                    >
                      <h1 className="hover:text-orange-500">OUR VENTURES</h1>
                      <IoMdArrowDropdown />
                    </div>
                    {toggle && (
                      <div className="absolute rounded-b-xl shadow-lg capitalize bottom-[-11.4rem] left-[-2rem] p-[1rem] text-sm w-[13rem] h-fit bg-white flex flex-col gap-2 font-bold ">
                        <a href="/service/1" className="hover:text-orange-500">
                        Real Estate- Commercials
                        </a>
                        <a href="/service/2" className="hover:text-orange-500">
                        Stone Crushers
                        </a>
                        <a href="/#banner" className="hover:text-orange-500">
                        Real Estate Layouts
                        </a>
                        <a href="/service/3" className="hover:text-orange-500">
                        Fuel Point- Nayara Energy
                        </a>
                      </div>
                    )}
                  </div>
                  {/* <select  className=" w-[13rem] text-black hover:text-orange-500 uupercase font-bold relative outline-none px-3 py-3">
                  <div className="absolute  ">OUR ExXPERTISE</div>
                  <option defaultValue="OUR EXPERTISE"></option>
                  <option value="Roogi stone crushers">Roogi stone crushers</option>
                  <option value="Roogi Real Estate ">Roogi Real Estate </option>
                  <option value="Roogi Fuel Point constructs">Roogi Fuel Point constructs</option>
                  </select> */}
                </div>
                <a
                  href="/projects"
                  className="hover:text-orange-500 uppercase font-bold duration-500 cursor-pointer"
                >
                  Our Projects
                </a>
                {/* <a
                  href="/blog"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  Blogs
                </a> */}
                <a
                  href="/contact"
                  className="hover:text-orange-500 uppercase font-bold duration-500 cursor-pointer"
                >
                  Contact Us
                </a>
              </div>
              <div onClick={() => setCard(true)}>
                <IoMdMenu className="text-[25px] text-gray-800" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
